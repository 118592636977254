import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import pdficon from "../images/pdf-icon.jpg";
import AQAR1920 from "../pdf/AQAR2019-20.pdf";
import pdfUGCourses from "../pdf/UG-Courses-Calendar-for-the-Session-2018.pdf";
import calender2019 from "../pdf/UGCoursesCalendarfortheSession2019.pdf";
import SSR from "../pdf/SSR.pdf";
import AQAR202021 from "../pdf/AQAR2020-21.pdf";
function AQAR(){
    return(
        <>
           <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>AQAR</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<h2 className="text-center mt-5 mb-5"></h2>
<section class="container mt-5 mb-5">
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>AQAR (Annual Quality Assurance Report)</h2>
                        <p>The Annual Quality Assurance Report (AQAR) is prepared by the Internal Quality Assurance Cell (IQAC) to document the academic and administrative activities of the college. It highlights the quality initiatives and improvements made throughout the year.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href={AQAR202021} target="_blank"><strong>AQAR 2020-21 report(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={AQAR202021} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
    </div>
    <hr />
    <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/AQAR%202017-18%20report.pdf?ver=1712054815428" target="_blank"><strong>AQAR 2017-18 report(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/AQAR%202017-18%20report.pdf?ver=1712054815428" target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
    </div>
    <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/AQAR%202018-19.pdf?ver=1712054815428" target="_blank"><strong>AQAR 2018-19(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/AQAR%202018-19.pdf?ver=1712054815428" target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href={AQAR1920} target="_blank"><strong>AQAR 2019-20(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href={AQAR1920} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        </section>
<Footer />
        </>
        );

}

export default AQAR;