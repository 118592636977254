import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Energy() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>Energy Club</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}

<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Energy Club</h2>
                        <p>The Energy Club focuses on raising awareness about energy conservation and the use of renewable energy sources. Students participate in projects related to energy efficiency, sustainable living, and green technologies. By learning about the latest advancements in the energy sector, students are better prepared for careers in this rapidly evolving field. The club also organizes workshops and events to promote the adoption of eco-friendly practices both on and off campus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>








            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Energy;