import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Career() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>Career and Placement Cell</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}

            <div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Career and Placement Cell</h2>
                        <p>The Career and Placement Cell at our college is dedicated to helping students transition smoothly from academic life to professional careers. Through personalized career counseling, resume writing workshops, and interview skills training, students receive the guidance they need to succeed in today's competitive job market. The cell also facilitates campus recruitment drives and job fairs, connecting students with potential employers and internship opportunities across various industries.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>








            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Career;