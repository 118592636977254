import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function SPARSH() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>SPARSH unit</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}

<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/sparsh.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>SPARSH (Sensitization, Prevention, and Redressal of Sexual Harassment)</h2>
                        <p>SPARSH is dedicated to the sensitization, prevention, and redressal of sexual harassment on campus. We are committed to creating a safe and respectful environment for all members of the college community.</p>
                        <ul>
                            <li>Awareness Programs</li>
                            <li>Reporting Mechanisms</li>
                            <li>Support Services</li>
                            <li>Policy Guidelines</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default SPARSH;