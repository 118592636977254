import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import Teacherimg from "../../images/teacher/ecnomics.jpg";

function Economics(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Economics Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
    <p>
    The Department of Economics, a pivotal Teaching Department within the School of Social Sciences, consistently furnishes the requisite workforce for diverse sectors, encompassing public, private, and international institutions. A degree program in Economics offers an exhilarating journey into the realm of commerce, with money and decision-making at its core. It mirrors the daily dilemmas individuals face, whether at home or in the workplace. Economics pervades our everyday existence as our desires invariably surpass our finite resources. This program delves into various subjects such as decision-making and opportunity cost, economic growth and development, public finance, inflation, exchange rates, interest rates, international trade, and entrepreneurship, among others, preparing individuals for the dynamic business landscape. Our alumni secure positions across key economic sectors like Banking, Insurance, Retail, and Manufacturing. Some opt for advanced studies in Economics, Finance, or Business Administration. Currently, the Department offers a Bachelor of Arts in Economics.
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Mr. Gurmel Singh</a></h3>
            <p>Assistant professor</p>
            <p>(Department of Economics)</p>
            {/* <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul> */}
          </div>
      </li>
    </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default Economics;