import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import AboutIMG from "../../images/principalbeetan.JPG";
import Teacherimg from "../../images/teacher/commerceone.jpg";
import Teachertwo from "../../images/teacher/commercetwo.jpg";
function Commerce(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Commerce Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
    <p>
    Enter the distinguished domain of our Commerce Department, where the intricate workings of business and finance merge with the finesse of strategic thought. Established with a commitment to excellence, our department is dedicated to arming undergraduate students with the knowledge and competencies necessary to navigate the multifaceted realm of commerce.<br />

Since our inception, we have remained resolute in our objective to empower students with a profound comprehension of commerce. Whether you're pursuing studies in Economics, Business Administration, or a related field, our devoted faculty members are on hand to offer unparalleled guidance and assistance.<br />

Join us as we embark on an exploration of the captivating terrain of commerce, from discerning market trends to mastering the intricacies of financial analysis. Together, let us unravel the enigmas and insights that shape the global economic landscape and pave the path for future triumphs.
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Mrs Gurjeet Kaur</a></h3>
            <p>Assistant Professor</p>
            <p>(Department of commerce)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teachertwo} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Mr. Ramesh Kumar</a></h3>
            <p>Assistant Professor</p>
            <p>(Department of commerce)</p>
          </div>
      </li>
    </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default Commerce;