import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import PTAIMG from "../images/pta.webp";

function PTA(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>PTA</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div className="container mt-5 pb-5">
    <div className="img-main">
        <img src={PTAIMG} />
        <p>PRINCIPAL DR.ANJORI SHARMA WITH PTA OFFICE BEARERS SESSION 2023-24</p>
    </div>
</div>
        <Footer />
        </>
    );
}

export default PTA;