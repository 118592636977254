import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Youth() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>Youth Red Cross Club</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}

<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src={ncc4} alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Youth Red Cross Club</h2>
                        <p>The Youth Red Cross Club empowers students to make a positive impact through humanitarian efforts and health initiatives. By participating in first aid training, blood donation drives, and community outreach programs, students gain a better understanding of health-related issues and how to address them effectively. The club fosters values such as compassion, empathy, and civic responsibility, inspiring students to become active contributors to the well-being of their communities.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>









            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Youth;