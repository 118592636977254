import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function AISHE() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>AISHE (All India Survey on Higher Education)</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}


<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/aishe.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>AISHE (All India Survey on Higher Education)</h2>
                        <p>The All India Survey on Higher Education (AISHE) collects and compiles data on various aspects of higher education institutions in India. Our college participates in AISHE to contribute to the national database of higher education statistics.</p>
                        <ul>
                            <li>Survey Data</li>
                            <li>Institutional Information</li>
                            <li>Performance Indicators</li>
                            <li>Statistical Reports</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default AISHE;