import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import SliderOne from "../images/slider-one.jpg";
import topback from "../images/beetitle.png";
import AboutIMG from "../images/principalbeetan.JPG";
import Mission from "../images/mission.png";
import Vission from "../images/vission.png";
function Committees() {
  return (
    <>
      <Header />
      <div className="section">
        {/* <!--Video Section--> */}
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
          <div class="pattern-overlay">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 title-heading">
                  <h2>Committees</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="container mt-5">
        <div class="row">
          
            

              <h3 class="m-0">College Advisory &CSCA Committee </h3>
              <p><strong>Our college has various committees that oversee different aspects of academic and administrative functions. These committees ensure effective governance, quality assurance, and smooth operation of college activities.
                Features:
                - List of Committees
                - Committee Members
                - Roles and Responsibilities
                - Meeting Minutes
              </strong></p>


           
          

        </div>

      </div>


      <Footer />
    </>
  );
}

export default Committees;