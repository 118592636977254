import logo from './logo.svg';
import './App.css';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/about_drop/Services";
import Our_guiding_principles from "./pages/about_drop/Our_guiding_principles";
import Principal from "./pages/staff_drop/Principal";
import Contact from "./pages/Contact";
import Faculty from "./pages/Faculty";
import Csca from "./pages/Csca";
import Gallery from "./pages/Gallery";
import Department from "./pages/Department";
import Acadimicsbank from "./pages/Acadimicsbank";
import Ncc from "./pages/Ncc";
import Nss from "./pages/Nss";
import Career from "./pages/careercell";
import Ranger from "./pages/Rangerover";
import Eco from "./pages/Ecoclub";
import Energy from "./pages/energyclub";
import Youth from "./pages/YRS";
import Electoral from "./pages/Electoralclub";
import Red from "./pages/RedRibbonclub";
import Road from "./pages/Roadsafetyclub";
import Library from "./pages/Library";
import Magazine from "./pages/Collegemagazine";
import AISHE from "./pages/AISHE";
import UGC from "./pages/UGC";
import SPARSH from "./pages/SPARSH";

import Evaluation from "./pages/Evaluation";
import Institutional from "./pages/Institutional";
import Students from "./pages/Students-achievements";
import Naac from "./pages/Naac";
import SSR from "./pages/SSR";
import PTA from "./pages/PTA";
import Committees from "./pages/Committees";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Chemistry from "./pages/staff_drop/Chemistry";
import Economics from "./pages/staff_drop/Economics";
import Commerce from "./pages/staff_drop/Commerce";
import Hindi from "./pages/staff_drop/Hindi";
import Math from "./pages/staff_drop/Math";
import Physics from "./pages/staff_drop/Physics";
import English from "./pages/staff_drop/English";
import History from "./pages/staff_drop/History";
import AQAR from "./pages/AQAR";
import Administationstaff from "./pages/Administationstaff";
import AcadimicsCalender from "./pages/AcadimicsCalender";
import OSA from "./pages/OSA";
import Scholarship from "./pages/Scholarship";
function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/about" element={<About />}></Route>
      <Route exact path="/services" element={<Services />}></Route>
      <Route exact path="/our-guiding-principles" element={<Our_guiding_principles />}></Route>
      <Route exact path="/principal" element={<Principal />}></Route>
      <Route exact path="/contact" element={<Contact />}></Route>
      <Route exact path="/faculty" element={<Faculty />}></Route>
      <Route exact path="/csca" element={<Csca />}></Route>
      <Route exact path="/gallery" element={<Gallery />}></Route>
      <Route exact path="/department" element={<Department />}></Route>
      <Route exact path="/contact" element={<Contact />}></Route>
      <Route exact path="/students-achievements" element={<Students />}></Route>
      <Route exact path="/naac" element={<Naac />}></Route>
      <Route exact path="/PTA" element={<PTA />}></Route>
      <Route exact path="/committees" element={<Committees />}></Route>
      <Route exact path="/chemistry" element={<Chemistry />}></Route>
      <Route exact path="/economics" element={<Economics />}></Route>
      <Route exact path="/commerce" element={<Commerce />}></Route>
      <Route exact path="/hindi" element={<Hindi />}></Route>
      <Route exact path="/math" element={<Math />}></Route>
      <Route exact path="/physics" element={<Physics />}></Route>
      <Route exact path="/english" element={<English />}></Route>
      <Route exact path="/history" element={<History />}></Route>
      <Route exact path="/AQAR" element={<AQAR />}></Route>
      <Route exact path="/administationstaff" element={<Administationstaff />}></Route>
      <Route exact path="/acadimicsCalender" element={<AcadimicsCalender />}></Route>
      <Route exact path="/osa" element={<OSA />}></Route>
      <Route exact path="/scholarship" element={<Scholarship />}></Route>
      <Route exact path="/evaluation" element={<Evaluation />}></Route>
      <Route exact path="/Institutional" element={<Institutional />}></Route>
      <Route exact path="/ssr" element={<SSR />}></Route>
      <Route exact path="/Acadimicsbank" element={<Acadimicsbank />}></Route>
      <Route exact path="/ncc" element={<Ncc />}></Route>
      <Route exact path="/nss" element={<Nss />}></Route>
      <Route exact path="/Career" element={<Career/>}></Route>
      <Route exact path="/Ranger" element={<Ranger/>}></Route>
      <Route exact path="/Eco" element={<Eco/>}></Route>
      <Route exact path="/Energy" element={<Energy/>}></Route>
      <Route exact path="/Youth" element={<Youth/>}></Route>
      <Route exact path="/Electoral" element={<Electoral/>}></Route>
      <Route exact path="/Red" element={<Red/>}></Route>
      <Route exact path="/Road" element={<Road/>}></Route>
      <Route exact path="/Library" element={<Library/>}></Route>
      <Route exact path="/Magazine" element={<Magazine/>}></Route>
      <Route exact path="/AISHE" element={<AISHE/>}></Route>
      <Route exact path="/UGC" element={<UGC/>}></Route>
      <Route exact path="/SPARSH" element={<SPARSH/>}></Route>
    </Routes>
  </Router>
  );
}

export default App;
