import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import AboutIMG from "../../images/principalbeetan.JPG";

function Our_guiding_principles(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Our Guiding Principles</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="section margin-top_50">
        <div class="container">
            <div class="row">
                <div class="col-md-6 layout_padding_2">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <h2><span>OUR GUIDING PRINCIPLES</span></h2>
                        </div>
						<div class="full">
                        <ul class="details">
								<li><a href="files/our_guiding_principles/energy.pdf"><i class="fa fa-chevron-right"></i> Energy</a></li>
                                <li><a href="files/our_guiding_principles/waste_management.pdf"><i class="fa fa-chevron-right"></i> Waste Management</a></li>
                                <li><a href="files/our_guiding_principles/water_conservation.pdf"><i class="fa fa-chevron-right"></i> Water Conservation</a></li>
                                <li><a href="files/our_guiding_principles/barrier-free.pdf"><i class="fa fa-chevron-right"></i> Disabled Friendly Barrier-free Campus</a></li>
                                <li><a href="files/green_campus.pdf"><i class="fa fa-chevron-right"></i> Our Policy for Maintaining a Green Campus</a></li>
                                <li><a href="files/vmo.pdf"><i class="fa fa-chevron-right"></i> Vision Mission and Objectives</a></li>
                                <li><a href="files/disabled_students.pdf"><i class="fa fa-chevron-right"></i> 26.03.2020 Adoption of Guidelines for DIVYANGJAN</a></li>
							</ul>
						</div>
						
                    </div>
                </div>
				<div class="col-md-6">
                    <div class="full mt-5">
                        <img src={AboutIMG} alt="#" />
                    </div>
                </div>
            </div>
        </div>
    </div>
        <Footer />
        </>
    );
}

export default Our_guiding_principles;