import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import pdficon from "../images/pdf-icon.jpg";
import pdfAcademic from "../pdf/Academic-Calendar-for-the-Session-2017.pdf";
import pdfUGCourses from "../pdf/UG-Courses-Calendar-for-the-Session-2018.pdf";
import calender2019 from "../pdf/UGCoursesCalendarfortheSession2019.pdf";
import Actiontaken from "../pdf/Actiontaken.pdf";
import IQACcomposition from "../pdf/composition & minutes of IQAC 2021-22.pdf"
import Actiontakens from "../pdf/0_feedback action taken 2021-22.pdf";
import AcademicCalendar from "../pdf/PDF Gallery_20240508_141034.pdf";
import SSR from "../pdf/SSR.pdf";
function Naac(){
    return(
        <>
           <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Naac</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="container mt-5 mb-5">
<h2 className="text-center mt-5 mb-5">IQAC Composition</h2>
<div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href="#" target="_blank"><strong>Data yet to be added</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href="#" target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
</div>
    <hr />
<h2 className="text-center mt-5 mb-5">IQAC Meeting Minutes</h2>
<div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2017-18.pdf?ver=1712053818116"><strong>Minutes of IQAC 2017-18</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2017-18.pdf?ver=1712053818116" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2018-19.pdf?ver=1712053818116"><strong>Minutes of IQAC 2018-19</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2018-19.pdf?ver=1712053818116" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2019-20.pdf?ver=1712053818116"><strong>Minutes of IQAC 2019-20</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2019-20.pdf?ver=1712053818116" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
<h2 className="text-center mt-5 mb-5">Student Satisfaction Survey (SSS)</h2>
<div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2.7.1.pdf?ver=1712053818005"><strong>SSS 2017-18</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/2.7.1.pdf?ver=1712053818005" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Student%20satisfaction%20survey%20for%20the%20session%202.docx?ver=1712053818005"><strong>SSS 2018-19</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Student%20satisfaction%20survey%20for%20the%20session%202.docx?ver=1712053818005" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
<h2 className="text-center mt-5 mb-5">Institute Distinctive Feature.</h2>
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Institute%20distinctive%20Feature.pdf?ver=1712053818005"><strong>Institute distinctive Feature(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Institute%20distinctive%20Feature.pdf?ver=1712053818005" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
<h2 className="text-center mt-5 mb-5">2021-22 Documents</h2>
<div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href={IQACcomposition} target="_blank"><strong>IQAC composition 2021-22(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={IQACcomposition} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
    </div>
    <hr />
    <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href={Actiontakens} target="_blank"><strong>Action taken report feedback 2021-22(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={Actiontakens} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
    </div>
    <hr />

    <hr style={{color: '#fb2032', borderBottom: '2px solid #fb2032'}} /><br /><br />
<div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href={Actiontaken} target="_blank"><strong>Feedback action taken report 2020-21(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={Actiontaken} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
    </div>
    <hr />



        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Continous%20Internal%20Evaluation.pdf?ver=1712052134703" target="_blank"><strong>Continous Internal Evaluation(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Continous%20Internal%20Evaluation.pdf?ver=1712052134703"target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Students%20Outcome%202017-18.xlsx?ver=1712052134703"><strong>Students Outcome 2017-18(xlsx)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Students%20Outcome%202017-18.xlsx?ver=1712052134703" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Best%20Practices%20adopted%20by%20the%20College.pdf?ver=1712052134703"><strong>Best Practices adopted by the College(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Best%20Practices%20adopted%20by%20the%20College.pdf?ver=1712052134703" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />

  

        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/best%20practices%202018-19%20(1).pdf?ver=1712053818005"><strong>Best practices 2018-19(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/best%20practices%202018-19%20(1).pdf?ver=1712053818005" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Students%20Outcome%202018-19.xlsx?ver=1712053818005"><strong>Students Outcome 2018-19(xlsx)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Students%20Outcome%202018-19.xlsx?ver=1712053818005" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/calendar%201st%20and%202nd%202019%20(1).docx?ver=1712053818116"><strong>Calendar 1st and 2nd 2019(docs)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/calendar%201st%20and%202nd%202019%20(1).docx?ver=1712053818116" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />

        </section>
        <Footer />
      </>
        );

}

export default Naac;