import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function UGC() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>UGC/NEP (University Grants Commission/National Education Policy) </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}


<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/ugc-nep.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>UGC/NEP (University Grants Commission/National Education Policy)</h2>
                        <p>Stay updated with the latest guidelines and policies from the University Grants Commission (UGC) and the National Education Policy (NEP). These policies aim to enhance the quality of higher education and promote holistic development.</p>
                        <ul>
                            <li>UGC Guidelines</li>
                            <li>NEP Updates</li>
                            <li>Policy Documents</li>
                            <li>Implementation Plans</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default UGC;