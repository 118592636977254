import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";

function Services(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Services</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div class="container mt-5 pb-5">
    <h2></h2>
	<div class="row">
		<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
						<div class="our-services-wrapper mb-60">
							<div class="services-inner">
								<div class="our-services-img">
								<img src="https://www.orioninfosolutions.com/assets/img/icon/Agricultural-activities.png" width="68px" alt="" />
								</div>
								<div class="our-services-text">
									<h4>NSS</h4>
									<p>Proin varius pellentesque nuncia tincidunt ante. In id lacus</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
						<div class="our-services-wrapper mb-60">
							<div class="services-inner">
								<div class="our-services-img">
								<img src="https://www.orioninfosolutions.com/assets/img/icon/Agricultural-activities.png" width="68px" alt="" />
								</div>
								<div class="our-services-text">
									<h4>NCC</h4>
									<p>Proin varius pellentesque nuncia tincidunt ante. In id lacus</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
						<div class="our-services-wrapper mb-60">
							<div class="services-inner">
								<div class="our-services-img">
								<img src="https://www.orioninfosolutions.com/assets/img/icon/Agricultural-activities.png" width="68px" alt="" />
								</div>
								<div class="our-services-text">
									<h4>BHARAT SCOUTS & GUIDE</h4>
									<p>Proin varius pellentesque nuncia tincidunt ante. In id lacus</p>
								</div>
							</div>
						</div>
					</div>
	</div>
    </div>
        <Footer />
        </>
    );
}

export default Services;