import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Nss() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>Nss unit</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}

            <div class="section margin-top_50">
                <div class="container mb-5 pb-5">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="full mt-5">
                                <img src={ncc2} alt="#" />
                            </div>
                        </div>
                        <div class="col-md-8 layout_padding_2">
                            <div class="full">
                                <div class="full">
                                    <h2>Nss (National Service Scheme)</h2>
                                    <p>Our National Service Scheme (Nss) unit encourages students to actively engage in community service and social work. By participating in outreach programs, such as health awareness campaigns, education initiatives, and environmental conservation projects, students learn the importance of giving back to society. Nss also fosters personal growth by developing empathy, teamwork, and leadership abilities while providing students with a platform to apply their academic knowledge in real-world settings.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>







            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Nss;