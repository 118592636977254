import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import PrincipalIMG from "../images/teacher/facprincipal.png";
import GURDEVSINGH from "../images/teacher/physics.jpg";
import DALIP from "../images/teacher/engli.jpg";
import GAURAV from "../images/teacher/mathteacher.jpg";
import REENA from "../images/teacher/hinditeacher.jpg";
import RECHA from "../images/teacher/chemest.jpg";
import GURMELSINGH from "../images/teacher/ecnomics.jpg";
import GURJEETKAUR from "../images/teacher/commerceone.jpg";
import BUTTA from "../images/teacher/Mr.BUTTARAM.webp";
import Uma from "../images/teacher/Uma.webp";
import RAMESH from "../images/teacher/commercetwo.jpg";
import JYOTISHARMA from "../images/teacher/historyteacher.jpg";
function Faculty(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>FACULTY</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull padding-lg">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={PrincipalIMG} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Dr. Sunita Goyal</a></h3>
            <p>(Principal)</p>
            <p>Mobile No: (9418614744)</p>
            {/* <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul> */}
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={GURDEVSINGH} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Mr.GURDEV SINGH</a></h3>
            <p>(ASSOCIATE PROF.PHYSICS)</p>
            <p>Mobile No: (9418151625)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Uma} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Prof.Uma Rani</a></h3>
            <p>(Associate Prof. of Sanskrit)</p>
            <p>Mobile No: (8595147528)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={DALIP} class="img-responsive" alt="" /></figure>
            <h3><a href="#">DR.DALEEP BALI</a></h3>
            <p>(Assistant Professor English)</p>
            <p>Mobile No: (9418489143)</p>
          </div>
       </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={GAURAV} class="img-responsive" alt="" /></figure>
            <h3><a href="#">DR. GAURAV SHARMA</a></h3>
            <p>(Assistant Professor Mathematics)</p>
            <p>Mobile No: (9736204600)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={REENA} class="img-responsive" alt="" /></figure>
            <h3><a href="#">DR REENA CHANDEL </a></h3>
            <p>(Assistant Professor Hindi)</p>
            <p>Mobile No: (9459004985)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={RECHA} class="img-responsive" alt="" /></figure>
            <h3><a href="#">MS RICHA SHARMA</a></h3>
            <p>(ASSISTANT Professor Chemistry)</p>
            <p>Mobile No: (9805889230)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={GURMELSINGH} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Mr.GURMAIL SINGH</a></h3>
            <p>(ASSISTANT Professor Economics)</p>
            <p>Mobile No: (9882063493)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={GURJEETKAUR} class="img-responsive" alt="" /></figure>
            <h3><a href="#">MS GURJEET KAUR</a></h3>
            <p>(Assistant Professor commerce)</p>
            <p>Mobile No: (9459229580)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={BUTTA} class="img-responsive" alt="" /></figure>
            <h3><a href="#">MR.BUTTA RAM</a></h3>
            <p>(Assistant Professor Pol.schence)</p>
            <p>Mobile No: (9805058418)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={RAMESH} class="img-responsive" alt="" /></figure>
            <h3><a href="#">MR. RAMESH KUMAR</a></h3>
            <p>(Assistant Professor commerce)</p>
            <p>Mobile No: (9459944562)</p>
          </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={JYOTISHARMA} class="img-responsive" alt="" /></figure>
            <h3><a href="#">MS.JYOTI SHARMA</a></h3>
            <p>(Assistant Professor History)</p>
            <p>Mobile No: (9760533307)</p>
          </div>
      </li>
    
    </ul>
  </div>
</section>
<Footer />
        </>
    );
}

export default Faculty;