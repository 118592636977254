import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Library() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>Library</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}

<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="ncc6" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Library</h2>
                        <p>The college library is a vibrant and resourceful hub that provides students with access to a vast collection of academic resources, including books, journals, magazines, and digital media. Our library offers a conducive and comfortable environment for study and research, equipped with modern amenities such as computers, Wi-Fi, and study spaces. Students can take advantage of the library's extensive digital library to access e-books and online journals from anywhere. Our dedicated librarians are available to assist students in locating resources and provide guidance on research projects. The library also organizes workshops on information literacy and research skills to enhance students' academic experience.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Library;