import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Logo from "../images/logo.jpg";
import I5 from "../images/i5.png";
import I6 from "../images/i6.png";
import I7 from "../images/i7.png";
function Footer(){
    return(
        <>
            {/* <!-- Start Footer --> */}
    <footer className="footer-box">
        <div className="container">
		
		   <div className="row">
		   
		      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
			     <div className="footer_blog">
				    <div className="margin-bottom_30">
					   <img src={Logo} alt="#" />
					 </div>
					 <div className="full white_fonts">
					    <p>S.D.W.G.Government Degree College Beetan was established on 23rd August, 2004 when the Government of Himachal Pradesh took it over from the private management of Sant Baba Dhangu Wale Ji. Ever since its inception in 1997, it has been imparting quality education to the students of the area. The College has been accredited by the NAAC wit </p>
					 </div>
				 </div>
			  </div>
			  
			  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
			       <div className="footer_blog footer_menu white_fonts">
						    <h3>Quick links</h3>
						    <ul> 
							  <li><Link to="/about"> About</Link></li>
							  <li><Link to="/faculty"> Faculty</Link></li>
							  <li><Link to="/csca"> CSCA</Link></li>
							  <li><Link to="/department"> Department</Link></li>
							  <li><Link to="/contact"> Contact us</Link></li>
							</ul>
						 </div>
				 </div>
				 
				 <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
				 <div className="footer_blog full white_fonts">
						     <h3>Newsletter</h3>
							 <p>Principal Govt.College Beetan distt Una (H.P)-176601.</p>
							 <div className="newsletter_form">
							    <form action="index.html">
								   <input type="email" placeholder="Your Email" name="#" required />
								   <button>Submit</button>
								</form>
							 </div>
						 </div>
					</div>	 
			  
			  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
				 <div className="footer_blog full white_fonts">
						     <h3>Contact us</h3>
							 <ul className="full">
							   <li><img src={I5} /><span>Beeton, Himachal Pradesh 174601</span></li>
							   <li><img src={I6} /><span>gcbeetan@gmail.com</span></li>
							   <li><img src={I7} /><span>+12586954775</span></li>
							 </ul>
						 </div>
					</div>	 
			  
		   </div>
		
        </div>
    </footer>
    {/* <!-- End Footer --> */}

    <div className="footer_bottom">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <p className="crp">© Copyrights 2024 All Right Reserved</p>
                </div>
            </div>
        </div>
    </div>

    <a href="#" id="scroll-to-top" className="hvr-radial-out"><i className="fa fa-angle-up"></i></a>
        </>
    );
}

export default Footer;