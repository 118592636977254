import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import Teacherimg from "../../images/teacher/mathteacher.jpg";

function Math(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Math Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
    <p>
    Welcome to our Mathematics Department, a place where we blend the magic of numbers with the thrill of solving problems. Since our start in 2004, we've been wholly dedicated to empowering undergraduate students to truly grasp the essence of mathematics. Whether you're aiming for a Bachelor of Science or Arts degree, our teachers are super passionate and ready to guide you every step of the way.<br /><br />

    Come join us on this adventure through the world of mathematics. We'll explore everything from the basics of calculus to the fascinating realm of abstract algebra and more. Together, let's unlock the secrets and wonders hidden within the amazing world of maths.
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Dr. Gaurav Sharma</a></h3>
            <p>Assistant Professor</p>
            <p>(Department of Mathematics)</p>
          </div>
      </li>
    </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default Math;