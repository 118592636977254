import React from "react";
import { Carousel } from 'react-bootstrap';
import SliderOne from "../images/sliderimg.png";
import SliderTwo from "../images/lastslider.png";
import SliderThree from "../images/three-sl.png";
import Tabpri from "../images/tabp.png";
function Slider(){
    return (
        <Carousel>
          <Carousel.Item>

            <img
              className="d-block w-100"
              src={SliderOne}/>
            <Carousel.Caption className="caption-animation text-fits">
              <div className="row">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6 text-set">
            <img
              className="d-block pro-tab"
              src={Tabpri} alt="Tabpri" />
          
              <h4 class="slideruper-text">You don’t have to be great to start, but you have to start to be great</h4>
              </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={SliderThree} alt="Second slide" />
            <Carousel.Caption className="caption-animation text-fit" style={{animationDelay: '1s'}}>
            <div className="uper-text">
            {/* <img
              className="d-block pro-tab"
              src={Tabpri} alt="Tabpri" /> */}
              </div>
            </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
            <img
              className="d-block w-100"
              src={SliderTwo} alt="Third slide"
            />
            <Carousel.Caption className="caption-animation text-fit" style={{animationDelay: '2s'}}>
            <div className="uper-text">
            {/* <img
              className="d-block pro-tab"
              src={Tabpri} alt="Tabpri" /> */}
              </div>
            </Carousel.Caption>
            </Carousel.Item>
        
        </Carousel>
      );
}

export default Slider;

<style></style>