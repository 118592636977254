import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import I1 from "../images/i1.png";
import I2 from "../images/i2.png";
import I3 from "../images/i3.png";
import I4 from "../images/i4.png";
import I5 from "../images/i5.png";
import I6 from "../images/i6.png";
import I7 from "../images/i7.png";
import P1 from "../images/allmas.jpg";
import P2 from "../images/second.jpg";
import P3 from "../images/thrid.webp";
import P4 from "../images/beetanclg.jpg";
import P5 from "../images/five.webp";
import P6 from "../images/six.webp";
import P7 from "../images/seven.webp";
import P8 from "../images/eight.webp";
import P9 from "../images/beetanclg.jpg";
import P10 from "../images/ten.webp";

function Gallery(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Gallery</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div className="gallery__page silver_bg">
		<div className="gallery__warp">
        <div className="container mt-5 mb-5 pb-5">
			<div className="row pt-5">
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P1} data-fresco-group="gallery">
						<img src={P1} alt="" style={{width:'100%'}} />
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P2} data-fresco-group="gallery">
						<img src={P2} alt="" style={{width:'100%'}}/>
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P3} data-fresco-group="gallery">
						<img src={P4} alt="" style={{width:'100%'}}/>
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P5} data-fresco-group="gallery">
						<img src={P5} alt="" style={{width:'100%'}}/>
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P6} data-fresco-group="gallery">
						<img src={P6} alt="" style={{width:'100%'}}/>
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P7} data-fresco-group="gallery">
						<img src={P7} alt="" style={{width:'100%'}}/>
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P8} data-fresco-group="gallery">
						<img src={P8} alt="" style={{width:'100%'}}/>
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P9} data-fresco-group="gallery">
						<img src={P9} alt="" style={{width:'100%'}}/>
					</a>
				</div>
				<div className="col-md-4">
					<a className="gallery__item fresco" href={P10} data-fresco-group="gallery">
						<img src={P10} alt="" style={{width:'100%'}}/>
					</a>
				</div>
			</div>
		</div>
	</div>
    </div>
        <Footer />
        </>
    );
}

export default Gallery;