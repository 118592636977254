import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import Teacherimg from "../../images/teacher/physics.jpg";
import Teacherstu from "../../images/teacher/studentphysics.jpg";
import Teacherstutwo from "../../images/teacher/checkphy.jpg";

function Physics(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Physics Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
    <p>
    The Department of Physics provides a diverse range of courses approved by H.P. University, Shimla. These courses cover both practical applications and theoretical fundamentals of physics. Among them are mechanics, electricity, magnetism, physics workshop skills, electrical circuits and networks, computational physics, solid-state physics, quantum mechanics, wave optics, statistical physics, elements of modern physics, nuclear and particle physics, renewable sources of energy, and more. The department is equipped with two well-furnished laboratories to facilitate practical learning.
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Prof. Gurdev Singh</a></h3>
            <p>( HOD Physics )</p>
          </div>
      </li>
    </ul>
  </div>
</section>
   <div class="container mb-5">
      <div class="row">
        <div class="col-sm-6 title-heading">
        <figure><img src={Teacherstu} class="img-responsive" alt="" /></figure>
            <p className="text-center"><b>Students performing experiments in the Laboratory.</b></p>
        </div>
        <div class="col-sm-6 title-heading">
        <figure><img src={Teacherstutwo} class="img-responsive" alt="" /></figure>
            <p className="text-center"><b>Imparting instruction during laboratory session</b></p>
        </div>
        </div>
        
        </div>
        <Footer />
        </>
    );
}

export default Physics;