import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import pdficon from "../images/pdf-icon.jpg";
import AcademicCalender202021 from "../pdf/AcademicCalender2021.pdf";
import Minutescomposition2021 from "../pdf/Minutes-composition20-21.pdf";
import pdfAcademic from "../pdf/Academic-Calendar-for-the-Session-2017.pdf";
import pdfUGCourses from "../pdf/UG-Courses-Calendar-for-the-Session-2018.pdf";
import calender2019 from "../pdf/UGCoursesCalendarfortheSession2019.pdf";
import Actiontaken from "../pdf/Actiontaken.pdf";
import IQACcomposition from "../pdf/composition & minutes of IQAC 2021-22.pdf"
import Actiontakens from "../pdf/0_feedback action taken 2021-22.pdf";
import AcademicCalendar from "../pdf/PDF Gallery_20240508_141034.pdf";
import SSR from "../pdf/SSR.pdf";

function AcadimicsCalender(){
    return(
        <>
           <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Academic Calenders</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<h2 className="text-center mt-5 mb-5"></h2>
<section class="container mt-5 mb-5">
    {/*<div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href={AcademicCalender202021} target="_blank"><strong> 2020-21(pdf)</strong></a></p>
                    
                     
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={AcademicCalender202021} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
            
            {/* <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                  
                    <p className="pdfpara"><a href={Minutescomposition2021} target="_blank"><strong> Minutes Composition 2020-21(pdf)</strong></a></p>
                     
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={Minutescomposition2021} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div> 
        </div>
    </div>*/}
    <div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/academic-calendar.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Academic Calendar</h2>
                        <p>The Academic Calendar outlines the schedule of academic activities for the year, including important dates for exams, holidays, and events. Stay informed about all the key dates to ensure you don’t miss out on any academic or extracurricular activities.</p>
                        <ul>
                            <li>Semester Dates</li>
                            <li>Examination Schedules</li>
                            <li>Holiday List</li>
                            <li>Event Calendar</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    <hr />
    <h2 className="text-center mt-5 mb-5">2021-22 Documents</h2>

    <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href={AcademicCalendar} target="_blank"><strong>Academic Calendar 2021-22(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={AcademicCalendar} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
    </div>
    <hr style={{color: '#fb2032', borderBottom: '2px solid #fb2032'}} /><br /><br />

    <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                    <p className="pdfpara"><a href={pdfAcademic} target="_blank"><strong>Academic Calendar for the Session 2017(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                     </div>
                </div>
                <div class="col-2 align-self-center"><a href={pdfAcademic} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
    </div>
    <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href={pdfUGCourses} target="_blank"><strong>UG Courses Calendar for the Session 2018(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href={pdfUGCourses} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href={calender2019} target="_blank"><strong>UG Courses Calendar for the Session 2019(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href={calender2019} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href={calender2019} target="_blank"><strong>UG Courses Academic Calendar for the Session 2(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href={calender2019} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href={calender2019} target="_blank"><strong>UG Courses Academic Calendar for the Session 2(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href={calender2019} target="_blank" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />


       
        </section>
<Footer />
        </>
        );

}

export default AcadimicsCalender;