import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Magazine() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>College Magazine</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}


<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>College Magazine</h2>
                        <p>The college magazine is a creative and informative publication that showcases the talents and achievements of our students and faculty. It features a diverse range of content, including articles, essays, poetry, artwork, and photography contributed by members of the college community. The magazine serves as a platform for students to express their thoughts, share experiences, and celebrate academic and extracurricular accomplishments. Through its pages, readers can stay updated on college events, news, and milestones. The magazine also promotes critical thinking and creativity among students, providing them with opportunities to hone their writing and editorial skills.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Magazine;