import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import topback from "../images/beetitle.png";
import PTAIMG from "../images/pta.webp";

function Acadimicsbank(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Academic Bank of Credits</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<div className="container mt-5 pb-5">
    <div className="schloarship-main">
        <p><section class="container">
    <h2>Importance of Academic Bank of Credit (ABC)</h2>
    <ul>
        <li><strong>Flexibility in Education:</strong> ABC allows students to accumulate credits from various sources, including formal education, online courses, internships, and work experience, enabling them to tailor their learning paths to their needs and interests.</li>
        <li><strong>Credit Transferability:</strong> It facilitates the seamless transfer of academic credits between educational institutions, both domestically and internationally, reducing administrative hurdles and ensuring recognition of prior learning experiences.</li>
        <li><strong>Cost-effectiveness:</strong> By streamlining credit transferability and recognizing prior learning, ABC promotes efficiency in education, potentially reducing the time and cost required to complete degrees.</li>
        <li><strong>Recognition of Prior Learning (RPL):</strong> ABC acknowledges and values prior learning experiences outside the traditional classroom setting, allowing individuals to have their skills and knowledge assessed and accredited.</li>
        <li><strong>Lifelong Learning:</strong> ABC encourages lifelong learning by providing a mechanism for individuals to continuously acquire new skills and knowledge throughout their lives, thereby enhancing their employability and career advancement prospects.</li>
        <li><strong>Empowerment of Learners:</strong> ABC empowers learners by recognizing diverse skills and knowledge, fostering a culture of continuous learning and skill development, and promoting a learner-centric approach to education.</li>
    </ul>
</section>

<section class="container">
    <h2>How to Generate Academic Bank of Credit (ABC) ID</h2>
    <ol>
        <li><strong>Access Digital Locker Platform:</strong> Log in to the digital locker platform provided by your educational institution or the organization managing the ABC system.</li>
        <li><strong>Navigate to ABC Section:</strong> Once logged in, navigate to the section or feature related to the Academic Bank of Credit (ABC).</li>
        <li><strong>Provide Personal Information:</strong> Enter your full name, date of birth, student ID number, contact details, and any other required information.</li>
        <li><strong>Upload Supporting Documents:</strong> Upload any required documents to verify your identity and eligibility.</li>
        <li><strong>Review and Confirm Information:</strong> Double-check the information you've provided for accuracy.</li>
        <li><strong>Submit Request:</strong> Submit your request to generate an ABC ID.</li>
        <li><strong>Verification Process:</strong> Wait for the verification of your information and documents.</li>
        <li><strong>ABC ID Generation:</strong> After successful verification, your ABC ID will be generated, and you'll receive a confirmation message.</li>
        <li><strong>Access ABC Records:</strong> Use the ABC section of the digital locker to manage your academic records and credits.</li>
        <li><strong>Utilize ABC ID:</strong> Use your ABC ID to accumulate credits, transfer credits between institutions, and access educational opportunities.</li>
    </ol>
    <p><strong>Instructions:</strong> Keep your ABC ID secure and confidential to protect your academic records and ensure the integrity of the system.</p>
</section></p>
    </div>
</div>
        <Footer />
        </>
    );
}

export default Acadimicsbank;