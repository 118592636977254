import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import Teacherimg from "../../images/teacher/hinditeacher.jpg";

function Hindi(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Hindi Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
    <p>
    हमारे प्रतिष्ठित हिंदी विभाग में आपका स्वागत है। यहां न केवल भाषा, साहित्य और संस्कृति के माध्यम से विशाल ज्ञान का सृजन होता है, बल्कि यहां एक गहरे अनुभव की खोज भी होती है। 2004 में गठित हुआ हमारा विभाग वास्तव में हिंदी भाषा के प्रेमियों के लिए एक अनमोल संसाधन है। यहां छात्रों को हिंदी भाषा के अनगिनत आयामों को समझने और उसे अपनाने का मौका प्राप्त होता है।<br /><br />


    हमारा विभाग हिंदी भाषा के गहरे ज्ञान को विकसित करने के साथ-साथ उसकी सांस्कृतिक और साहित्यिक विरासत को भी महत्व देता है। यहां के विद्यार्थियों को भारतीय साहित्य, कविता, कहानी, नाटक, और उपन्यास की अद्वितीय दुनिया के प्रति प्रेरित किया जाता है। उन्हें विभिन्न काव्य और गद्य साहित्य  के माध्यम से समय का साक्षात्कार करने का अवसर प्राप्त होता है। इसके अलावा, हम छात्रों को सामाजिक और राजनीतिक मुद्दों पर भी विचार करने के लिए प्रोत्साहित करते हैं, ताकि वे समाज में अधिक जागरूक और सक्रिय नागरिक बन सकें।<br /><br />

    हमारे विभाग के प्रध्यापकों का शैक्षिक और विचारिक स्तर साहित्यिक क्षेत्र में प्रशिक्षितता का साक्षात्कार है। उनका अनुभव और ज्ञान हमारे विद्यार्थियों को अद्वितीय शिक्षा प्रदान करने में सहायक होता ह| हम सभी मिलकर हिंदी भाषा के उज्जवल भविष्य के लिए प्रयासरत हैं।<br />
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Dr Reena Chandel</a></h3>
            <p>Assistant Professor</p>
            <p>(Department of Hindi)</p>
          </div>
      </li>
    </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default Hindi;