import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import AboutIMG from "../../images/principalbeetan.JPG";
import Teacherimg from "../../images/teacher/chemest.jpg";

function Chemistry(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Chemistry Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
    <p>
    The aim of department of chemistry is to inculcate critical thinking, reasoning, scientific skills and chemical knowledge among undergraduate students by providing them with quality smart class room, well equipped chemistry labs and research opportunities. The main focus of department is to train students for future perspectives towards scientific area so that they can give their contribution for new scientific reforms. The Department makes effort to develop creativity, happiness, energy, interest, satisfaction, truth and responsibility, among the students for young India by engaging students in different activities like group discussion, seminars, quizzes and chemical industrial tours etc. 
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Ms Richa Sharma</a></h3>
            <p>Assistant Professor</p>
            <p>(M.Sc. B.ED, SET, NET-JRF)</p>
            {/* <ul class="follow-us clearfix">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul> */}
          </div>
      </li>
    </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default Chemistry;