import React from "react";
import Header from "../../pages/Header";
import Footer from "../../pages/Footer";
import topback from "../../images/beetitle.png";
import Teacherimg from "../../images/teacher/engli.jpg";

function English(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>English Department</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="our-webcoderskull mt-5">
  <div class="container">
    <div class="section-text">
        <h4 className="text-center"><i>“Knowledge of Language is the doorway to Wisdom”</i></h4>
    <p>
    The Department of English has been successfully functioning in the Institution right since the inception of the College in 1997. Presently, the Department has two sanctioned posts of Assistant/Associate Professors to cater to the academic needs of the students. English as a compulsory subject is being taught to the students of all the three streams [Arts,Science and Commerce] as per the guidelines of HP University. Besides that, English is available for studies as an Elective subject,both as Major or Minor, to all those  students who are keen in pursuing Bachelor in Arts [pass course] degree. The Department was further augmented in its academic domain in the year 2018,when Post-graduate course[M.A. English] was introduced in the College. The Department is committed to the cause of students and striving hard to impart quality education to its subjects. The paramount aim of the Department is to prepare the young minds for better career avenues and vouch for their resplendent future. 
    </p>
    </div>
    </div>
  </section>
<section class="our-webcoderskull">
  <div class="container">
    <div class="row heading heading-icon">
        <h2></h2>
    </div>
    <ul class="row padding-end" style={{paddingLeft: '0px'}}>
      <li class="col-12 col-md-6 col-lg-3">
          <div class="cnt-block equal-hight" style={{height: '349px;'}}>
            <figure><img src={Teacherimg} class="img-responsive" alt="" /></figure>
            <h3><a href="#">Dr.Daleep Bali</a></h3>
            <p>Assistant Professor</p>
            <p>(Department of English)</p>
          </div>
      </li>
    </ul>
  </div>
</section>
        <Footer />
        </>
    );
}

export default English;