import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Road() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>Road Safety Club</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}

<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src={ncc5} alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Road Safety Club</h2>
                        <p>The Road Safety Club promotes responsible and safe behavior on the road, aiming to reduce accidents and injuries. Through awareness campaigns, workshops, and safety demonstrations, students learn about the importance of obeying traffic rules, the impact of distracted driving, and other road safety concerns. The club's initiatives help create a culture of safety, contributing to the well-being of the community and saving lives.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Road;