import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import pdficon from "../images/pdf-icon.jpg";
import pdfAcademic from "../pdf/Academic-Calendar-for-the-Session-2017.pdf";
import pdfUGCourses from "../pdf/UG-Courses-Calendar-for-the-Session-2018.pdf";
import calender2019 from "../pdf/UGCoursesCalendarfortheSession2019.pdf";
import Actiontaken from "../pdf/Actiontaken.pdf";
import IQACcomposition from "../pdf/composition & minutes of IQAC 2021-22.pdf"
import Actiontakens from "../pdf/0_feedback action taken 2021-22.pdf";
import AcademicCalendar from "../pdf/PDF Gallery_20240508_141034.pdf";
import SSR from "../pdf/SSR.pdf";
function Institutional(){
    return(
        <>
           <Header />
        <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>Institutional Best Practices</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
<section class="container mt-5 mb-5">


        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/best%20practices%202018-19%20(1).pdf?ver=1712053818005"><strong>Best practices 2018-19(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/best%20practices%202018-19%20(1).pdf?ver=1712053818005" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        <hr />
        <div class="div1">
        <div class="container">
            <div class="row">
                <div class="col-2 d-inline float-none align-self-center"><img class="border rounded pdficon" src={pdficon} style={{width: '128px;'}} /></div>
                <div class="col-6">
                <p className="pdfpara"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Best%20Practices%20adopted%20by%20the%20College.pdf?ver=1712052134703"><strong>Best Practices adopted by the College(pdf)</strong></a></p>
                </div>
                <div class="col-2 text-center justify-content-center align-self-center">
                    <div class="progress d-flex align-items-lg-center">
                    </div>
                </div>
                <div class="col-2 align-self-center"><a href="https://img1.wsimg.com/blobby/go/1fbcb0a9-6146-4fda-8f90-353697c301e5/downloads/Best%20Practices%20adopted%20by%20the%20College.pdf?ver=1712052134703" class="btn btn-back" style={{width: '150px;', height: '36px;'}}>Download  <i class="icon ion-ios-arrow-thin-down"></i></a></div>
            </div>
        </div>
        </div>
        </section>
        <Footer />
      </>
        );

}

export default Institutional;