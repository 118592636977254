import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
import ncc1 from "../images/ncc1.jpg";
import ncc2 from "../images/ncc2.jpg";
import ncc3 from "../images/ncc3.jpg";
import ncc4 from "../images/ncc4.jpg";
import ncc5 from "../images/ncc5.jpg";
import ncc6 from "../images/ncc6.jpg";
function Ncc() {
    return (
        <>
            <Header />
            <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
                <div class="pattern-overlay">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 title-heading">
                                <h2>NCC unit</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- section --> */}
            <div class="section margin-top_50">
                <div class="container mb-5 pb-5">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="full mt-5">
                                <img src={ncc1} alt="#" />
                            </div>
                        </div>
                        <div class="col-md-8 layout_padding_2">
                            <div class="full">
                                <div class="full">
                                    <h2>NCC (National Cadet Corps)</h2>
                                    <p>The National Cadet Corps (NCC) at our college offers students an excellent opportunity to engage in a wide range of military training and leadership development activities. As part of the NCC, students participate in regular drill practice, adventure camps, and community service initiatives that help instill discipline and a sense of patriotism. Cadets also gain valuable life skills, such as teamwork, time management, and problem-solving, that can benefit them in both their personal and professional lives.</p>
                                </div>







                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section margin-top_50">
                <div class="container mb-5 pb-5">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="full mt-5">
                                <img src={ncc2} alt="#" />
                            </div>
                        </div>
                        <div class="col-md-8 layout_padding_2">
                            <div class="full">
                                <div class="full">
                                    <h2>NSS (National Service Scheme)</h2>
                                    <p>Our National Service Scheme (NSS) unit encourages students to actively engage in community service and social work. By participating in outreach programs, such as health awareness campaigns, education initiatives, and environmental conservation projects, students learn the importance of giving back to society. NSS also fosters personal growth by developing empathy, teamwork, and leadership abilities while providing students with a platform to apply their academic knowledge in real-world settings.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Career and Placement Cell</h2>
                        <p>The Career and Placement Cell at our college is dedicated to helping students transition smoothly from academic life to professional careers. Through personalized career counseling, resume writing workshops, and interview skills training, students receive the guidance they need to succeed in today's competitive job market. The cell also facilitates campus recruitment drives and job fairs, connecting students with potential employers and internship opportunities across various industries.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src={ncc3} alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Ranger and Rover</h2>
                        <p>The Ranger and Rover program at our college fosters a spirit of adventure and exploration among students. By engaging in outdoor activities such as hiking, camping, and trekking, students develop resilience, confidence, and a deep connection with nature. Additionally, students participate in community service projects, building their teamwork skills and contributing positively to society. This program provides students with a well-rounded experience that enhances their personal and professional growth.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Eco Club</h2>
                        <p>Our Eco Club promotes environmental awareness and encourages students to adopt sustainable practices in their daily lives. Through initiatives such as tree planting drives, waste management workshops, and educational campaigns, students learn the importance of conserving natural resources and protecting the environment. The club also organizes events to raise awareness about climate change and other pressing environmental issues, empowering students to become responsible stewards of the planet.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Energy Club</h2>
                        <p>The Energy Club focuses on raising awareness about energy conservation and the use of renewable energy sources. Students participate in projects related to energy efficiency, sustainable living, and green technologies. By learning about the latest advancements in the energy sector, students are better prepared for careers in this rapidly evolving field. The club also organizes workshops and events to promote the adoption of eco-friendly practices both on and off campus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src={ncc4} alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Youth Red Cross Club</h2>
                        <p>The Youth Red Cross Club empowers students to make a positive impact through humanitarian efforts and health initiatives. By participating in first aid training, blood donation drives, and community outreach programs, students gain a better understanding of health-related issues and how to address them effectively. The club fosters values such as compassion, empathy, and civic responsibility, inspiring students to become active contributors to the well-being of their communities.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Electoral Literacy Club</h2>
                        <p>The Electoral Literacy Club is dedicated to educating students about the democratic process and the importance of voting. Through interactive workshops, debates, and simulations, students gain a deeper understanding of their rights and responsibilities as citizens. The club also organizes voter registration drives and awareness campaigns to promote civic participation and encourage informed decision-making in elections.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Red Ribbon Club</h2>
                        <p>The Red Ribbon Club is focused on raising awareness about HIV/AIDS and promoting safe and healthy practices. Students participate in education campaigns, workshops, and community outreach programs to reduce stigma and support those affected by HIV/AIDS. The club emphasizes the importance of sexual health education and works towards creating a more inclusive and accepting environment for everyone.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src={ncc5} alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Road Safety Club</h2>
                        <p>The Road Safety Club promotes responsible and safe behavior on the road, aiming to reduce accidents and injuries. Through awareness campaigns, workshops, and safety demonstrations, students learn about the importance of obeying traffic rules, the impact of distracted driving, and other road safety concerns. The club's initiatives help create a culture of safety, contributing to the well-being of the community and saving lives.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="ncc6" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Library</h2>
                        <p>The college library is a vibrant and resourceful hub that provides students with access to a vast collection of academic resources, including books, journals, magazines, and digital media. Our library offers a conducive and comfortable environment for study and research, equipped with modern amenities such as computers, Wi-Fi, and study spaces. Students can take advantage of the library's extensive digital library to access e-books and online journals from anywhere. Our dedicated librarians are available to assist students in locating resources and provide guidance on research projects. The library also organizes workshops on information literacy and research skills to enhance students' academic experience.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>College Magazine</h2>
                        <p>The college magazine is a creative and informative publication that showcases the talents and achievements of our students and faculty. It features a diverse range of content, including articles, essays, poetry, artwork, and photography contributed by members of the college community. The magazine serves as a platform for students to express their thoughts, share experiences, and celebrate academic and extracurricular accomplishments. Through its pages, readers can stay updated on college events, news, and milestones. The magazine also promotes critical thinking and creativity among students, providing them with opportunities to hone their writing and editorial skills.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/academic-calendar.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>Academic Calendar</h2>
                        <p>The Academic Calendar outlines the schedule of academic activities for the year, including important dates for exams, holidays, and events. Stay informed about all the key dates to ensure you don’t miss out on any academic or extracurricular activities.</p>
                        <ul>
                            <li>Semester Dates</li>
                            <li>Examination Schedules</li>
                            <li>Holiday List</li>
                            <li>Event Calendar</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>AQAR (Annual Quality Assurance Report)</h2>
                        <p>The Annual Quality Assurance Report (AQAR) is prepared by the Internal Quality Assurance Cell (IQAC) to document the academic and administrative activities of the college. It highlights the quality initiatives and improvements made throughout the year.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/aishe.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>AISHE (All India Survey on Higher Education)</h2>
                        <p>The All India Survey on Higher Education (AISHE) collects and compiles data on various aspects of higher education institutions in India. Our college participates in AISHE to contribute to the national database of higher education statistics.</p>
                        <ul>
                            <li>Survey Data</li>
                            <li>Institutional Information</li>
                            <li>Performance Indicators</li>
                            <li>Statistical Reports</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/ugc-nep.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>UGC/NEP (University Grants Commission/National Education Policy)</h2>
                        <p>Stay updated with the latest guidelines and policies from the University Grants Commission (UGC) and the National Education Policy (NEP). These policies aim to enhance the quality of higher education and promote holistic development.</p>
                        <ul>
                            <li>UGC Guidelines</li>
                            <li>NEP Updates</li>
                            <li>Policy Documents</li>
                            <li>Implementation Plans</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section margin-top_50">
    <div class="container mb-5 pb-5">
        <div class="row">
            <div class="col-md-4">
                <div class="full mt-5">
                    <img src="images/sparsh.jpg" alt="#" />
                </div>
            </div>
            <div class="col-md-8 layout_padding_2">
                <div class="full">
                    <div class="full">
                        <h2>SPARSH (Sensitization, Prevention, and Redressal of Sexual Harassment)</h2>
                        <p>SPARSH is dedicated to the sensitization, prevention, and redressal of sexual harassment on campus. We are committed to creating a safe and respectful environment for all members of the college community.</p>
                        <ul>
                            <li>Awareness Programs</li>
                            <li>Reporting Mechanisms</li>
                            <li>Support Services</li>
                            <li>Policy Guidelines</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







            {/* <!-- end section --> */}
            <Footer />
        </>
    );
}

export default Ncc;