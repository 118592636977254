import React from "react";
import Header from "../pages/Header";
import Footer from "../pages/Footer";
import topback from "../images/beetitle.png";
function Department(){
    return(
        <>
        <Header />
  <section class="content-section title-top" style={{ backgroundImage: `url(${topback})` }}>
  <div class="pattern-overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 title-heading">
        <h2>DEPARTMENT</h2>  
	   </div>
      </div>
    </div>
  </div>
</section>
{/* <!-- section --> */}
    <div class="section layout_padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">01</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">ENGLISH DEPARTMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Dr.Daleep Bali (Assistant Prof.)</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p>WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U/G LEVEL AND PG IN M.A.ENGLISH.</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND ENGLISH LANGUAGE LAB.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">02</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">ECONOMICS</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Mr.Gurmel Singh (Assistant Prof.)</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p>WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U/G LEVEL.</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND WELL EQUIPPED LIBRARY.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">03</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">CHEMISTRY DEPARTMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Dr.Ashwini Kumar</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p>WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U/G LEVEL. </p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND TWO LABORATORIES FOR PRACTICAL EXPERIMENTS.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">04</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">COMMERCE DEPARTMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>1.Mr. RAMESH KUMAR (Assistant Prof.)</p>
            <p>2.Ms Gurjeet Kaur (Assistant Prof.)</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p>WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U.G LEVELS.</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND WELL EQUIPPED LIBRARY.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">05</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">HINDI DEPARTMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Dr.Reena Chandel (Assistant Prof.)</p>

					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p> WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U.G LEVELS. </p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p> HI-TECH CLASS ROOMS AND WELL EQUIPPED LIBRARY. </p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">06</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">HISTORY</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Prof.Jyoti Sharma (Assistant Prof.)</p>

					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p> WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U.G LEVELS. </p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND WELL EQUIPPED LIBRARY.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">07</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">MATHEMATICS DEPARTMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Mr.Gaurav Sharma (Assistant Prof.)</p>

					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p>WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U.G LEVELS</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND WELL EQUIPPED LIBRARY. </p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">08</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">PHYSICS DEPARTMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Mr. GURDEV SINGH.</p>

					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p> WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U.G LEVELS.</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>Hi-Tech  Smart class rooms and well equipped two Laboratories for Practicals.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">09</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">POLITICAL SCIENCE DEPARTMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Mr. BUTTA RAM.</p>

					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p>WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U.G LEVELS.</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND WELL EQUIPPED LIBRARY.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
    <div class="section mb-5">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="full">
                        <div class="heading_main text_align_left">
						   <div class="left">
						     <p class="section_count">10</p>
						   </div>
						   <div class="right">
						    <p class="small_tag"></p>
                            <h2><span class="theme_color">SANSKRIT DEPARMENT</span></h2>
                          </div>	
                        </div>
                    </div>
                </div>
            </div>
			<div class="row margin-top_30">
			
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACULTY</h4>
					</div>
					<div class="full">
					  <p>Dr. Uma Rani ( M.A. Phd )</p>

					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>COURSES OFFERED</h4>
					</div>
					<div class="full">
					  <p>WE OFFER COURSES AS PER HIMACHAL PRADESH UNIVERSITY SHIMLA AT U.G LEVELS.</p>
					</div>
				 </div>
			   </div>
			   
			   <div class="col-sm-6 col-md-4">
			     <div class="service_blog">
					<div class="full">
					   <h4>FACILITIES FOR STUDENTS</h4>
					</div>
					<div class="full">
					  <p>HI-TECH CLASS ROOMS AND WELL EQUIPPED LIBRARY.</p>
					</div>
				 </div>
			   </div>
			 </div>
        </div>
    </div>
	{/* <!-- end section --> */}
  <Footer />
        </>
    );
}

export default Department;